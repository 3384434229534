/** @jsx jsx */
import { jsx, Themed, Box, Flex } from 'theme-ui';
import { CallToAction } from '@sprinklr/shared-lib';
import { Product, CTA } from '@sprinklr/shared-lib/@types/entities';
import { Description } from '../types';
import ProductInfoHeader from './ProductInfoHeader';

type HighlightContentProps = {
  product: Product;
  callToActions: CTA[];
  headline: string;
  description: Description;
};

const EMPTY_ARRAY_READONLY = [];

const ProductInfo: React.FC<HighlightContentProps> = ({
  product,
  callToActions = EMPTY_ARRAY_READONLY,
  headline,
  description,
}) => {
  return (
    <Flex
      sx={{
        pr: [0, 5, 5],
        flexDirection: 'column',
        width: ['100%', '600px', '600px'],
      }}
    >
      <ProductInfoHeader sx={{ display: ['none', 'flex'] }} product={product} />
      <Flex
        sx={{
          flexDirection: 'column',
          height: '100%',
          justifyContent: ['center', 'space-around', 'space-around'],
        }}
      >
        <Themed.h2
          sx={{
            fontWeight: ['bold', 'medium'],
            fontSize: [5, 6, 8],
            pb: [, 3],
            textAlign: ['center', 'left'],
          }}
        >
          {headline}
        </Themed.h2>
        <Themed.p
          sx={{
            textAlign: ['center', 'left'],
          }}
        >
          {description?.description}
        </Themed.p>
      </Flex>
      {callToActions?.length > 0 ? (
        <Flex
          sx={{
            height: ['48px', '60px'],
            justifyContent: ['center', 'flex-start', 'flex-start'],
            mt: [0, 4, 4],
          }}
        >
          {callToActions.map(cta => (
            <Box key={cta.text} sx={{ mr: [0, 3, 3, 3] }}>
              <CallToAction {...cta} />
            </Box>
          ))}
        </Flex>
      ) : null}
    </Flex>
  );
};

export default ProductInfo;
