/** @jsx jsx */
import { jsx, Themed, Flex } from 'theme-ui';
import { ProductIcon } from '@sprinklr/shared-lib';

import { Product } from '@sprinklr/shared-lib/@types/entities';

type Props = {
  product: Product;
};

const ProductInfoHeader: React.FC<Props> = ({ product, ...props }) => {
  const { shortName, name } = product || {};
  const productIconSx = {
    color: shortName,
    height: ['28px', '58px', '58px'],
    width: ['28px', '58px', '58px'],
    mb: [3, 'initial'],
  };

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: ['100%', '600px', '600px'],
      }}
      {...props}
    >
      <Flex
        sx={{
          flexFlow: ['column', 'row'],
          justifyContent: ['center', 'center', 'left'],
          alignItems: 'center',
          pb: [3, 4],
        }}
      >
        <ProductIcon product={product} iconSx={productIconSx} />
        <Themed.p
          sx={{
            color: 'grey',
            pl: 3,
            mb: [3, 0],
            fontSize: [6, 4],
            fontWeight: ['bold', 'body'],
          }}
        >
          {name}
        </Themed.p>
      </Flex>
    </Flex>
  );
};

export default ProductInfoHeader;
