/** @jsx jsx */
import { jsx, Box, Flex, Themed } from 'theme-ui';
import { CallToAction, ProductIcon, RichText } from '@sprinklr/shared-lib';

import { Product, PBO, CTA } from '@sprinklr/shared-lib/@types/entities';

type PBOsContentProps = {
  product: Product;
  callToActions: CTA[];
  headline: string;
};
const EMPTY_ARRAY_READONLY = [];

const PBOItem = ({ highlightText, index }) => {
  const itemIndex = index + 1;
  return (
    <Flex
      key={index}
      sx={{
        pb: [0, 3, 3],
      }}
    >
      <Box
        sx={{
          minWidth: ['24px', '24px'],
          color: 'text',
          fontSize: [5, 10],
          fontWeight: 'bold',
          mr: [0, '12px'],
        }}
      >
        {itemIndex}
      </Box>
      <Box
        sx={{
          mt: 1,
        }}
      >
        <pre
          sx={{
            whiteSpace: 'pre-wrap',
          }}
        >
          <RichText richTextObject={highlightText} />
        </pre>
      </Box>
    </Flex>
  );
};

const ProductBusinessOutcome: React.FC<PBOsContentProps> = ({
  product,
  callToActions = EMPTY_ARRAY_READONLY,
  headline,
}) => {
  const { shortName, name, pbos } = product;
  const productIconSx = {
    color: shortName,
    height: ['24px', '50px', '50px'],
    width: ['24px', '50px', '50px'],
  };
  return (
    <Flex
      sx={{
        flexDirection: 'column',
        width: ['100%', '540px', '540px'],
      }}
    >
      <Flex
        sx={{
          flexDirection: ['row', 'row'],
          height: ['30px', '56px'],
          alignItems: 'center',
          mb: 3,
        }}
      >
        <ProductIcon product={product} iconSx={productIconSx} />
        <Themed.h4
          sx={{
            mb: 0,
            pl: [2, 3, 3],
          }}
        >
          {name}
        </Themed.h4>
      </Flex>
      <Themed.p
        sx={{
          pb: 2,
          fontSize: [2, 3, 3],
          fontWeight: 'regular',
        }}
      >
        {headline}
      </Themed.p>
      <Flex
        sx={{
          justifyContent: ['flex-start', 'flex-start', 'flex-start'],
          flexDirection: 'column',
          height: '100%',
          wordBreak: 'break-word',
        }}
      >
        {pbos.map((pbo, index) => (
          <PBOItem
            highlightText={pbo.highlightText}
            index={index}
            productName={shortName}
            key={index}
          />
        ))}
      </Flex>
      {callToActions.length > 0 && (
        <Flex
          sx={{
            height: ['48px', '60px'],
            justifyContent: ['center', 'flex-start', 'flex-start'],
            pt: 3,
          }}
        >
          {callToActions.map(cta => (
            <Box key={cta.text} mr={[0, 3]}>
              <CallToAction {...cta} />
            </Box>
          ))}
        </Flex>
      )}
    </Flex>
  );
};

export default ProductBusinessOutcome;
