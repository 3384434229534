/** @jsx jsx */

import { Box, Flex, jsx, useThemeUI } from 'theme-ui';

import { Video } from '@sprinklr/shared-lib';
import ProductInfo from './variants/ProductInfo';
import ProductBusinessOutcome from './variants/ProductBusinessOutcome';
import { CallToAction } from '@sprinklr/shared-lib';

import { Container } from '@sprinklr/shared-lib';

import { ProductHeroProps } from './types';
import ProductInfoHeader from './variants/ProductInfoHeader';
import { getLinearGradient } from '@sprinklr/shared-lib/utils/getLinearGradient';

const ProductHero: React.FC<ProductHeroProps> = ({
  product,
  sectionId,
  headline,
  description,
  callToActions,
  video,
  thumbnail,
  imageAlignment,
  variant = 'PBO',
  buyersGuide,
}) => {
  const flexDirection = imageAlignment === 'RIGHT' ? 'row-reverse' : 'row';

  const { theme } = useThemeUI();

  return (
    <Container id={sectionId} fullWidth>
      <Flex
        sx={{
          pl: ['22px', '80px'],
          pr: ['22px', 0, 0, '80px'],
          maxWidth: ['100%', '100%', '100%', '1520px'],
          m: [0, 0, 0, '0 auto'],
        }}
      >
        <Flex
          sx={{
            alignItems: 'flex-start',
            backgroundColor: 'transparent',
            flexDirection: ['column', 'column', flexDirection],
            justifyContent: 'space-between',
            pb: [0, 4, 4],
            position: 'relative',
            width: '100%',
            ':before': {
              background: getLinearGradient(
                theme.rawColors[product?.shortName],
              ),
              content: '""',
              display: ['block', 'none'],
              height: '100%',
              margin: '-24px',
              marginTop: '-40px',
              position: 'absolute',
              width: '100vw',
              zIndex: '-1',
            },
          }}
        >
          {variant === 'INFO' ? (
            <ProductInfoHeader
              sx={{ display: ['flex', 'none'] }}
              product={product}
            />
          ) : null}
          <Flex
            sx={{
              alignItems: [
                'center',
                imageAlignment === 'RIGHT' ? 'flex-start' : 'flex-end',
              ],
              flexDirection: 'column',
              width: ['100%', 'auto'],
            }}
          >
            <Box
              sx={{
                pl: imageAlignment === 'RIGHT' ? [0, 0, 4, 4] : 0,
                pr: imageAlignment === 'RIGHT' ? [0, 0, 3, 0] : [0, 0, 4, 4],
                mt: variant === 'INFO' ? [0, '100px', '100px'] : 0,
                maxWidth: '100%',
              }}
            >
              {/*
 // @ts-ignore */}
              <Video poster={thumbnail?.file?.url} source={video?.file?.url} />
            </Box>
            {buyersGuide && (
              <Box
                sx={{
                  display: ['none', 'none', 'block'],
                  pt: 4,
                  px: [0, 4],
                }}
              >
                <CallToAction {...buyersGuide} />
              </Box>
            )}
          </Flex>
          <Flex
            sx={{
              pt: [4, 4, 0],
            }}
          >
            {variant === 'INFO' ? (
              <ProductInfo
                callToActions={callToActions}
                product={product}
                headline={headline}
                description={description}
              />
            ) : (
              <ProductBusinessOutcome
                callToActions={callToActions}
                product={product}
                headline={headline}
              />
            )}
          </Flex>
          {buyersGuide && (
            <Flex
              sx={{
                display: ['block', 'block', 'none'],
                pt: [3, 4],
                alignSelf: ['center', 'start'],
              }}
            >
              <CallToAction {...buyersGuide} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export default ProductHero;
